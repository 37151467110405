import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/core'

export default () => {
  return (
    <React.Fragment>
      {
        false &&
        <Link to="/newsletter" activeClassName="active" aria-label="View crash courses"
          css={css`
            &:hover {
              text-decoration: underline;
            }
          `}
        >
          <span role="img" aria-label="email newsletter" aria-labelledby="">📬</span>Newsletter
        </Link>        
      }
      <Link to="/crash-courses" activeClassName="active" aria-label="View crash courses"
        css={css`
          &:hover {
            text-decoration: underline;
          }
        `}
      >
        <span role="img" aria-label="fire" aria-labelledby="">🔥</span>Crash Courses
      </Link>
      {
        false &&
        <Link to="/quick-questions" activeClassName="active" aria-label="View quick questions"
          css={css`
            &:hover {
              text-decoration: underline;
            }
          `}
        >
          <span role="img" aria-label="thinking" aria-labelledby="">🤔</span>Quick Questions
        </Link>        
      }
      <Link to="/blog" activeClassName="active" aria-label="View blog page"
        css={css`
          &:hover {
            text-decoration: underline;
          }
        `}
      >
        <span role="img" aria-label="fire" aria-labelledby="">📔</span>Blog
      </Link>

    </React.Fragment>
  )
}
