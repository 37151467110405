module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/blog your pathPrefix should be "blog"
  siteTitle: 'Chris Achard', // Navigation and Site Title
  siteTitleAlt: "I'm Chris!", // Alternative Site title for SEO
  siteTitleShort: 'Chris Achard', // short_name for manifest
  siteUrl: 'https://chrisachard.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/images/chris_new.png', // Used for SEO and manifest, path to your image you placed in the 'static' folder
  siteDescription: 'Chris Achard',
  author: 'Chris Achard', // Author for schemaORGJSONLD
  organization: 'Chris Achard',

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@chrisachard', // Twitter Username
  ogSiteName: 'Chris Achard', // Facebook Site Name
  ogLanguage: 'en_US',
  googleAnalyticsID: 'UA-17371938-3',

  // Manifest and Progress color
  themeColor: '#5348FF',
  backgroundColor: '#2b2e3c',

  // Social component
  twitter: 'https://twitter.com/chrisachard/',
  twitterHandle: '@chrisachard',
  github: 'https://github.com/chrisachard/',
  linkedin: '',
}
